<template>
  <fragment>
    <h1 class="mb-3">Create/find your company</h1>
    <div v-html="introBlurb"></div>

    <form class="mb-3" @submit.prevent>
      <div class="alert alert-info" v-if="isUserExisting">
        <span class="mr-2">This is an already registered email address. Please <router-link to="/login">login</router-link> to access the system.</span>
      </div>

      <div class="alert alert-info" v-if="isCompanyNew">
        <span class="mr-2">This company does not exist, would you like to create?</span>
        <button type="button" class="btn btn-primary" @click="gotoCreate">Yes, I would like to create company</button>
      </div>

      <div class="alert alert-info" v-if="isCompanyExisting">
        <span class="mr-2">This company already exists, would you like to request to join?</span>
        <button type="button" class="btn btn-primary" @click="gotoJoin">Yes, I would like to join this company</button>
      </div>

      <div class="form-group">
        <label for="emailLookup">Email</label>
        <input type="text" id="emailLookup" name="emailLookup" class="form-control" :class="{ 'is-invalid': errors.has('emailLookup') }"
          autocomplete="off" v-model="email" v-validate="'required|url'">
        <div class="invalid-feedback">Please provide an email address.</div>
      </div>

      <div class="form-group">
        <label for="companyLookup">
          Find A Company
        </label>
        <vue-bootstrap-typeahead
          id="companyLookup"
          name="companyLookup"
          v-model="query"
          :data="companies"
          :serializer="company => company.name"
          placeholder="Type a minimum 3 characters to start the search"
          :minMatchingChars="3"
          :maxMatches="3"
          @hit="selectCompany($event)"
          ref="companyLookup"
        >
          <template slot="suggestion" slot-scope="{ data }">{{ data.name }}</template>
        </vue-bootstrap-typeahead>
        <input type="hidden" id="hasCompany" name="hasCompany" v-validate="'required:true'" v-model="query" class="form-control"
          :class="{ 'is-invalid': errors.has('hasCompany') }" />
        <div class="invalid-feedback">Please select a company</div>
      </div>

      <div class="d-flex flex-row-reverse bd-highlight">
        <button type="submit" class="btn btn-primary ld-ext-right" :class="{ 'running' : isChecking }" @click="verifyCompany">
          <span v-if="isChecking">Checking...</span>
          <span v-else>Verify</span>
          <div class="ld ld-ring ld-spin"></div>
        </button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { CompanyService, UserService } from '@/services/'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import debounce from "lodash/debounce";

export default {
  name: 'public-company-lookup',
  components: {
    VueBootstrapTypeahead
  },
  data: function () {
    return {
      companies: [],
      company: null,
      email: null,
      introBlurb: process.env.VUE_APP_TEMPLATE_SIGNUP_BLURB,
      isChecking: false,
      isCompanyNew: false,
      isCompanyExisting: false,
      isUserExisting: false,
      query: ''
    }
  },
  watch: {
    query: debounce(
      async function (newQuery) {
        let companies = await CompanyService.getByTypeahead(decodeURIComponent(newQuery));
        this.companies = companies.data;
      }, 500, false
    )
  },
  computed: {
    hasCompany () {
      return !!this.company;
    },
  },
  methods: {
    gotoCreate () {
      this.$router.push({ name: 'signup-company-new' });
    },
    gotoJoin () {
      this.$router.push({ name: 'signup-company-join', params: { slug: this.company.alias } });
    },
    selectCompany (company) {
      this.company = company;
    },
    async verifyCompany () {
      var isFormValid = await this.$validator.validate();
      if (!isFormValid) {
        return;
      }

      this.isChecking = true;
      this.isCompanyNew = false;
      this.isCompanyExisting = false;
      this.isUserExisting = false;

      try {
        let isValidEmail = await UserService.verifyEmail(this.email);

        this.isUserExisting = isValidEmail.data;

        if (!this.isUserExisting) {
          if (this.company === null || (this.company !== null && this.company.name !== this.query)) {
            this.isCompanyNew = true;
          } else {
            this.isCompanyExisting = true;
          }
        }

        this.$store.dispatch('setPublicSignupInfo', { company: this.query, email: this.email });
        this.isChecking = false;
      }
      catch {
        this.isChecking = false;
      }
    }
  }
};
</script>
